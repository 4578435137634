import React from 'react';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
import SearchList from "../SearchList/SearchList";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import SearchPreloader from "../Common/SearchPreloader";

class SearchPage extends React.Component {
  render() {
    const {loading, search} = this.props;
    return (
      <div style={{flex: '1'}} className="page">
        <section style={{ minHeight: '422px'}} className="blog-details-area">
          {(loading) ? <SearchPreloader/> :
            <SearchList data={search.data}/>
          }
        </section>
        <Footer/>
        <GoTop scrollStepInPx="50" delayInMs="16.66"/>
      </div>
    );
  }
}

const mapStateToProps = ({persistence, commonData}) => {
  const {loading} = commonData;
  const {search} = persistence;
  return {loading, search}
};

export default connect(mapStateToProps, {push})(SearchPage);
