import React, { Component } from 'react';

export class Busca extends Component {
    render() {
        return (
          <section className="ctr-area">
                <div className="container">
                      <div className="section-title">
                          <h2>Resultados de <span>Busca</span></h2>
                      </div>
                    
                </div>
            </section>
        )
      }
}

export default Busca
