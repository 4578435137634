import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-area">
        <div className="container">
          <p className="text-responsive">Agência de Inovação - Secretaria de Informática - Sistema Integrado de
            Bibliotecas - Fundação de Apoio Institucional - NIT Materiais - UFSCar</p>
          <ul className="list-inline">
            <li className="list-inline-item"><a href="http://inovacao.ufscar.br/">
              <img src={require('../../assets/images/logo-ain.png')} alt="partner" id="main-logo-footer"/></a></li>
            <li className="list-inline-item"><a href="https://www.sin.ufscar.br">
              <img src={require('../../assets/images/logo-sin.svg')} alt="partner" id="main-logo-footer"/></a></li>
            <li className="list-inline-item"><a href="http://www.sibi.ufscar.br">
              <img src={require('../../assets/images/logo-sib.svg')} alt="partner" id="main-logo-footer"/></a></li>
            <li className="list-inline-item"><a href="http://www.fai.ufscar.br/">
              <img src={require('../../assets/images/logo-fai.png')} alt="partner" id="main-logo-footer"/></a></li>
            <li className="list-inline-item"><a href="http://www.nit.ufscar.br/">
              <img src={require('../../assets/images/logo-nit.png')} alt="partner" id="main-logo-footer"/></a></li>
            <li className="list-inline-item"><a href="http://www2.ufscar.br/">
              <img src={require('../../assets/images/logo-ufscar-fundo-transparente.png')} alt="partner" id="main-logo-footer"/></a></li>
          </ul>
        </div>
        <div className="container">
          <ul>
            <li><a href="http://www.facebook.com/pages/Ag%C3%AAncia-de-Inova%C3%A7%C3%A3o-da-UFSCar/290154194340693" ><span className="fa fa-facebook"></span></a></li>
            <li><a href="http://twitter.com/ufscar_inova" ><span className="fa fa-twitter"></span></a></li>
            <li><a href="http://www.youtube.com/user/ufscarinovacao" ><span className="fa fa-youtube"></span></a></li>
          </ul>
          <p>Direitos reservados <i className="fa fa-copyright"></i> 2019 UFSCar - Agência de Inovação</p>
        </div>
      </footer>
    );
  }
}

export default Footer;