import React, { Component } from 'react';
import Footer from '../Common/Footer';

export class Empresas extends Component {
    render() {
        return (
          <section className="ctr-area">
                <div className="container">
                      <div className="section-title">
                          <h2>Empresas <span>Filhas</span></h2>
                      </div>
                    <div className="empresas">
                       
                          <ul className="list-inline">
                            <li className="list-inline-item"><a href="https://sflabs.com.br/">
                            <img src={require('../../assets/images/cia01.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://vetrabrazil.wixsite.com/vetra">
                            <img src={require('../../assets/images/cia02.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://invillia.com/">
                            <img src={require('../../assets/images/cia03.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://www.pete.com.br/">
                            <img src={require('../../assets/images/cia04.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://www.crceram.com.br/">
                            <img src={require('../../assets/images/cia05.jpg')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://https://sureale.com/">
                            <img src={require('../../assets/images/cia06.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://kopptechnologies.com/">
                            <img src={require('../../assets/images/cia07.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://afinkopolimeros.com.br/">
                            <img src={require('../../assets/images/cia08.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://www.tokenlab.com.br/pt/">
                            <img src={require('../../assets/images/cia09.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://www.brazitec.com.br/">
                            <img src={require('../../assets/images/cia10.jpg')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://www.engenhariaetec.com.br/">
                            <img src={require('../../assets/images/cia11.jpg')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://www.testservice.com.br/">
                            <img src={require('../../assets/images/cia12.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://www.indb.com.br/">
                            <img src={require('../../assets/images/cia13.jpg')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://teckidsteens.com.br/escola-online/">
                            <img src={require('../../assets/images/cia14.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://www.educacross.com.br/">
                            <img src={require('../../assets/images/cia15.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://itera.com.br/">
                            <img src={require('../../assets/images/cia16.jpg')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://www.dubbi.com.br/recentes">
                            <img src={require('../../assets/images/cia17.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://www.rogalvao.com.br">
                            <img src={require('../../assets/images/cia18.jpg')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://www.propz.com.br/">
                            <img src={require('../../assets/images/cia19.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://partyou.com.br/">
                            <img src={require('../../assets/images/cia20.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://stepwise.com.br/">
                            <img src={require('../../assets/images/cia21.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://taggen.com.br/">
                            <img src={require('../../assets/images/cia22.jpg')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://paysmart.com.br/">
                            <img src={require('../../assets/images/cia23.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://www.iteleport.com.br/">
                            <img src={require('../../assets/images/cia24.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://mindconsulting.com.br">
                            <img src={require('../../assets/images/cia25.jpg')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://start-turn.com.br/">
                            <img src={require('../../assets/images/cia26.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://www.sintesoft.com/">
                            <img src={require('../../assets/images/cia27.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://vexpenses.com/">
                            <img src={require('../../assets/images/cia28.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://www.atcp.com.br/empresa.html#">
                            <img src={require('../../assets/images/cia29.jpg')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://www.facebook.com/centro.aprendizagem.desenvolvimento/">
                            <img src={require('../../assets/images/cia30.png')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="http://nchemi.com/">
                            <img src={require('../../assets/images/cia31.jpg')} alt="partner" id="main-logo-footer"/></a></li>
                            <li className="list-inline-item"><a href="https://www.sacadasdeprofessor.com.br">
                            <img src={require('../../assets/images/cia32.png')} alt="partner" id="main-logo-footer"/></a></li>                    
                         </ul>
                          
                    </div>
                </div>
              <Footer/>
            </section>
             
        )
       
      }
      
}

export default Empresas
