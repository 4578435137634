// export const BASE_URL = 'http://localhost:8080/api';
export const BASE_URL = 'http://ain.ufscar.br/api/';
export const API_BASE_URL = BASE_URL;
export const ACCESS_TOKEN = 'access_token';
export const ACCESS_AUTH = 'auth';
export const AUTH_SERVER_CLIENT = 'npclient';
export const AUTH_SERVER_SECRET = 'npsecret';

export const POLL_LIST_SIZE = 30;
export const MAX_CHOICES = 6;
export const POLL_QUESTION_MAX_LENGTH = 140;
export const POLL_CHOICE_MAX_LENGTH = 40;

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;
