import React, {useState} from 'react';
import {Button, Row, Drawer, Form, Input, Col} from "antd";

const pStyle = {
  fontSize: 16,
  color: 'rgba(0,0,0,0.85)',
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

const ProfilePatente = (props) => {
  const DescricaoItem = ({content}) => (
    <div
      style={{
        fontSize: 14,
        lineHeight: '22px',
        marginBottom: 7,
        color: 'rgba(0,0,0,0.65)',
      }}
    >
      <p
        style={{
          marginRight: 8,
          display: 'inline-block',
          color: 'rgba(0,0,0,0.85)',
        }}
      >
        Descrição:
      </p>
      <span dangerouslySetInnerHTML={{__html: content}}></span>
    </div> 

    
  );

  const DescriptionItem = ({title, content}) => (
    <div
      style={{
        fontSize: 14,
        lineHeight: '22px',
        marginBottom: 7,
        color: 'rgba(0,0,0,0.65)',
      }}
    >
      <p
        style={{
          marginRight: 8,
          display: 'inline-block',
          color: 'rgba(0,0,0,0.85)',
        }}
      >
        {title}:
      </p>
      {content}
    </div>
  );
 const {getFieldDecorator} = props.form;

  return (
    <Drawer
      width={(global.window.innerWidth > 520)? "40%" : "90%"}
      placement="right"
      closable={false}
      onClose={props.close}
      visible={props.visible}
    >
      <p style={pStyle}>Detalhes</p>
    
      <Col>
        <Row span={12}>
          {(props.type === 'patentes')}
          <DescriptionItem title="Número da Patente" content={props.item.INPI_Processo}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Área Técnica" content={props.item.Areatecnica}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Website" content={props.item.Acharsite}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Título" content={props.item.INPI_Titulo}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Descrição" content={props.item.resumo_site}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Status" content={props.item.status_site}/>
        </Row>
      </Col>

    <div
        style={{
          textAlign: 'left',
        }}

        
      > 
      <a href="https://gru.inpi.gov.br/pePI/">Clique aqui para o resumo, documento completo e mais detalhes no site do INPI</a>
        
      </div>  


      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}

        
      > 
        <Button onClick={props.close} style={{marginRight: 8}}>
          Fechar
        </Button>
      </div>
    </Drawer>
  );
};

export default Form.create()(ProfilePatente);
