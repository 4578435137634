import {
  CATEGORIES_FETCH_SUCCESS,
  DATA_FETCH_SUCCESS,
  DISPLAYSECTIONS_FETCH_SUCCESS,
  CATEGORY_SAVE_SUCCESS,
  DISPLAYSECTION_SAVE_SUCCESS,
  NEWS_SAVE_SUCCESS,
  CATEGORY_DELETE_SUCCESS,
  DISPLAYSECTION_DELETE_SUCCESS,
  AUTHORS_FETCH_SUCCESS,
  NEWS_FETCH_SUCCESS, SET_EDIT_ITEM
} from "../constants/ActionTypes";
import {SEARCH_SUCCESS} from "../constants/ActionTypes";

const INIT_STATE = {
  categoriesList: [],
  displaySectionsList: [],
  authorsList: [],
  newsList: [],
  search: {
    // params : {},
    data: []
  },
  pesquisador: [],
  empresa: [],
  instituicao: [],
  laboratorio: [],
  equipamento: [],
  editItem: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CATEGORIES_FETCH_SUCCESS: {
      return {
        ...state,
        categoriesList: action.payload
      }
    }
    case SET_EDIT_ITEM: {
      return {
        ...state,
        editItem: action.payload
      }
    }
    case DATA_FETCH_SUCCESS: {
     return {
        ...state,
       data: [ ...action.payload.data ]
     }
    }
    case DISPLAYSECTIONS_FETCH_SUCCESS: {
      return {
        ...state,
        displaySectionsList: action.payload
      }
    }
    case NEWS_FETCH_SUCCESS: {
      return {
        ...state,
        newsList: action.payload
      }
    }
    case SEARCH_SUCCESS: {
      return {
        ...state,
        search: {
          data: {...action.payload.data}
        }
      }
    }
    case AUTHORS_FETCH_SUCCESS: {
      return {
        ...state,
        authorsList: action.payload
      }
    }
    case CATEGORY_SAVE_SUCCESS: {
      const category = action.payload;
      const categoriesList = [...state.categoriesList];
      const index = categoriesList.map(e => e.id).indexOf(category.id);

      if (index >= 0)
        categoriesList[index] = category;
      else
        categoriesList.push(category);

      return {
        ...state,
        categoriesList: categoriesList
      }
    }
    case DISPLAYSECTION_SAVE_SUCCESS: {
      const displaySection = action.payload;
      const displaySectionsList = [...state.displaySectionsList];
      const index = displaySectionsList.map(e => e.id).indexOf(displaySection.id);

      if (index >= 0)
        displaySectionsList[index] = displaySection;
      else
        displaySectionsList.push(displaySection);

      return {
        ...state,
        displaySectionsList: displaySectionsList
      }
    }
    case NEWS_SAVE_SUCCESS: {
      const news = action.payload;
      const newsList = [...state.newsList];
      const index = newsList.map(e => e.id).indexOf(news.id);

      if (index >= 0)
        newsList[index] = news;
      else
        newsList.push(news);

      return {
        ...state,
        newsList: newsList
      }
    }
    case CATEGORY_DELETE_SUCCESS: {
      const id = action.payload;
      const categoriesList = [...state.categoriesList];
      return {
        ...state,
        categoriesList: categoriesList.filter(category => category.id !== id)
      }
    }
    case DISPLAYSECTION_DELETE_SUCCESS: {
      const id = action.payload;
      const displaySectionsList = [...state.displaySectionsList];
      return {
        ...state,
        displaySectionsList: displaySectionsList.filter(displaySection => displaySection.id !== id)
      }
    }
    default:
      return state;
  }
}
