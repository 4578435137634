import React from 'react'

class GoTop extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateWindow = this.updateWindow.bind(this)
    }

    state = {
        intervalId: 0,
        thePosition: false
    };
    updateWindow = () => {
        if (window.scrollY > 170) {
            this.setState({ thePosition: true })
        } else {
            this.setState({ thePosition: false })
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.updateWindow);
        window.scrollTo(0, 0);
    }
    componentWillUnmount() {
        document.removeEventListener("scroll", this.updateWindow);
    }

    onScrollStep = () => {
        if (window.pageYOffset === 0){
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop = () => {
        let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

    renderGoTopIcon = () => {
        if (this.state.thePosition){
            return (
                <div className="go-top" onClick={this.scrollToTop}><i className="fa fa-angle-up"></i></div>
            )
        }
    }

    render(){
        return (
            <React.Fragment>
                {this.renderGoTopIcon()}
            </React.Fragment>
        )
    }
}

export default GoTop;
