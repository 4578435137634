import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import AppFront from "../app-front/AppFront";
import asyncComponent from "../util/asyncComponent";
import {IntlProvider} from "react-intl";
import AppLocale from "../lngProvider";
import {connect} from "react-redux";

class App extends Component {

  render() {
    const {locale} = this.props;
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <React.Fragment>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <Switch>
            {/*<Redirect exact from={`${match.url}/`} to={`${match.url}/home`}/>*/}
            <Redirect exact from="/" to="/aindex" />
            <Route path='/aindex' component={AppFront}/>
            <Route path="/" component={asyncComponent(() => import('./AppAdmin'))}/>
            {/*<Route path='/' component={AppAdmin}/>*/}
          </Switch>
        </IntlProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale} = settings;
  return {locale}
};

export default connect(mapStateToProps, null)(App);

