import React from 'react';
import Particles from 'react-particles-js';
import Search from "../Search/Search";
// import AnchorLink from "react-anchor-link-smooth-scroll";

const particleOpt = {
    particles: {
        number: {
            value: 120,
            density: {
                enable: true,
                value_area: 800
            }
        },
        color: {
            value: '#fff'
        },
        size: {
            value: 3
        }
    },
    interactivity: {
        events: {
            onhover: {
                enable: true,
                mode: "repulse"
            }
        }
    }
}
 
class MainBanner extends React.Component {
    render(){
        return (
            <div id="home" className="main-banner item-bg-one">
                <Particles
                    params={{...particleOpt}}
                />
                <Search />

                {/*<div className="creative-banner-one"></div>*/}
                {/*<div className="creative-banner-three"></div>*/}
                {/*<div className="creative-banner-one"></div>*/}
            </div>
        );
    }
}
 
export default MainBanner;