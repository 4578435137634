import React from 'react';
import WhoWeAre from '../Common/WhoWeAre';
import Funfact from '../Common/Funfact';
import HowWeWork2 from '../Common/HowWeWork2';
import Contact from '../Common/Contact';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';

class Home extends React.Component {
  render() {
    return (
      <div className="page">
        <HowWeWork2/>
        <WhoWeAre/>
        <Funfact/>
        <Contact/>
        <Footer/>
        <GoTop scrollStepInPx="50" delayInMs="16.66"/>
      </div>
    );
  }
}

export default Home;