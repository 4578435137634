import React from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router";
import {connect} from "react-redux";
import {fetchUser, setInitUrl} from "../redux/actions";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import Navigation from "./Navigation/Navigation";
import MainBanner from "./Pages/MainBanner";
import Home from "./Pages/Home";
import Busca from "./Pages/Busca";
import Empresas from "./Pages/Empresas";
import SearchPage from "./Pages/SearchPage";
import InfoView from "../components/InfoView";

class AppFront extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    const bodyEl = document.getElementsByTagName('body')[0];
    bodyEl.classList.add('aindex-frontend');
    bodyEl.classList.remove('indigo');
    // this.demoAsyncCall().then(() => this.setState({loading: false}));
  }

  // demoAsyncCall = () => {
  //   return new Promise(resolve => setTimeout(() => resolve(), 2000));
  // };

  // hashLinkScroll = () => {
  //   const {hash} = window.location;
  //   if (hash !== '') {
  //     setTimeout(() => {
  //       const id = hash.replace('#', '');
  //       const element = document.getElementById(id);
  //       if (element) element.scrollIntoView();
  //     }, 0);
  //   }
  // };

  render() {
    const {match, location} = this.props;
    return (
      <React.Fragment>
        <Navigation/>
        <MainBanner/>
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={{enter: 300, exit: 300}}
            classNames={'fade'}
          >
            <Switch location={location}>
              <Redirect exact from={`${match.url}/`} to={`${match.url}/home`}/>
              <Route path={`${match.url}/home`} component={Home}/>
              <Route path={`${match.url}/search`} component={SearchPage}/>
              <Route path={`${match.url}/empresas`} component={Empresas}/>
              <Route path={`${match.url}/busca`} component={Busca}/>
             </Switch>
          </CSSTransition>
        </TransitionGroup>
        <InfoView />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser, initURL} = auth;
  return {authUser, initURL}
};

export default withRouter(connect(mapStateToProps, {setInitUrl, fetchUser})(AppFront));
