import React from 'react';
import { Link } from 'react-router-dom'; 
class WhoWeAre extends React.Component {
    render(){
        return (
            // <section id="areas" className="who-we-are ptb-80 ">
            <section id="processo" className="about-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Áreas de <span>inovação</span></h2>
                        <p>Essas são as categorias de pesquisa:</p>
                    </div>
                    <div className="row">
                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-users"></i>
                                <h4>Pesquisadores</h4>
                                <span>1</span>
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-building"></i>
                                <h4>Instituições</h4>
                                <span>2</span>
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-briefcase"></i>
                                <h4>Empresas</h4>
                                <span>3</span>
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-flask"></i>
                                <h4>Laboratórios</h4>
                                <span>4</span >
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-cogs"></i>
                                <h4>Equipamentos</h4>
                                <span>5</span>
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-gavel"></i>
                                <h4>Patentes</h4>
                                <span>6</span>
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-search"></i>
                                <h4>Pesquisas</h4>
                                <span>7</span>
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-wrench"></i>
                                <h4>Serviços</h4>
                                <span>8</span>
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-file-text"></i>
                                <h4>Documentos</h4>
                                <span>9</span>
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-code"></i>
                                <h4>Tecnologias</h4>
                                <span>10</span>
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-line-chart"></i>
                                <h4>Projetos</h4>
                                <span>11</span>
                            </div>
                            </Link>
                        </div>

                        <div className="col col-lg-3 col-md-4 col-sm-6"><Link to="./busca">
                            <div className="single-who-we-are">
                                <i className="fa fa-book"></i>
                                <h4>Resoluções</h4>
                                <span>12</span>
                            </div>
                            </Link>
                        </div>
                        
                    </div>
                </div>
            </section>
        );
    }
}
 
export default WhoWeAre;