import React, {useState} from 'react';
import {Button, Row, Drawer, Form, Input, Col} from "antd";

const pStyle = {
  fontSize: 16,
  color: 'rgba(0,0,0,0.85)',
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

const ProfileDocumento = (props) => {
  const DescricaoItem = ({content}) => (
    <div
      style={{
        fontSize: 14,
        lineHeight: '22px',
        marginBottom: 7,
        color: 'rgba(0,0,0,0.65)',
      }}
    >
      <p
        style={{
          marginRight: 8,
          display: 'inline-block',
          color: 'rgba(0,0,0,0.85)',
        }}
      >
        Descrição:
      </p>
      <span dangerouslySetInnerHTML={{__html: content}}></span>
    </div>
  );

  const DescriptionItem = ({title, content}) => (
    <div
      style={{
        fontSize: 14,
        lineHeight: '22px',
        marginBottom: 7,
        color: 'rgba(0,0,0,0.65)',
      }}
    >
      <p
        style={{
          marginRight: 8,
          display: 'inline-block',
          color: 'rgba(0,0,0,0.85)',
        }}
      >
        {title}:
      </p>
      {content}
    </div>
  );

  const {getFieldDecorator} = props.form;

  return (
    <Drawer
      width={(global.window.innerWidth > 520)? "40%" : "90%"}
      placement="right"
      closable={false}
      onClose={props.close}
      visible={props.visible}
    >
      <p style={pStyle}>Detalhes</p>
      
      <Col>
        <Row span={12}>
          {(props.type === 'documentos')}
          <DescriptionItem title="Descrição" content={props.item.descricao}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Data do Documento" content={props.item.data_documento}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Arquivo" content={props.item.arquivo}/>
        </Row>
      </Col>

      

      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button onClick={props.close} style={{marginRight: 8}}>
          Fechar
        </Button>
      </div>
    </Drawer>
  );
};

export default Form.create()(ProfileDocumento);
