import Auth from 'util/Auth'
import {API_BASE_URL, AUTH_SERVER_CLIENT, AUTH_SERVER_SECRET} from "../redux/constants";

export const rawRequest = (url, request) => {
    return fetch(url, request)
        .then(response => 
            response.json().then(json => {
                if (response.ok)
                    return json;

                return Promise.reject(new Error(json.error_description));
            })
            .catch(error => {
                if (response.ok)
                    return {};

                return Promise.reject(error);
            })
        )
        .catch(error => error);
}

const request = (options, auth=true) => {
    const request = {
        method: options.method,
        headers: new Headers()
    };

    if (auth && Auth.isUserAuthenticated())
        request.headers.append('Authorization', 'Bearer ' + Auth.getToken());

    if (options.body) {
        request.headers.append('Content-Type', 'application/json');

        request.body = JSON.stringify(options.body);
    }

    return rawRequest(options.url, request);
};

export function uploadFile(file) {
    const request = {
        method: 'POST',
        headers: new Headers()
    };

    if (Auth.isUserAuthenticated())
        request.headers.append('Authorization', 'Bearer ' + Auth.getToken());

    var data = new FormData();

    data.append("upload", file);

    request.body = data;

    return rawRequest(API_BASE_URL+'/news/medias/', request);
}

export function login(loginRequest) {
    const formData = new FormData();

    let p = "";
    for (p in loginRequest) {
        formData.append(p, loginRequest[p]);
    }

    const headers = {
        'Authorization' : 'Basic '+btoa(AUTH_SERVER_CLIENT+':'+AUTH_SERVER_SECRET)
    };
    const request = {
        method: 'POST',
        headers: headers,
        body: formData,
    };

    return rawRequest(API_BASE_URL +'/oauth/token', request);
}

export function logout() {
    return request({
        url: API_BASE_URL + '/oauth/token/revoke',
        method: 'DELETE'
    });
}

export function saveUserRequest(user, isSignUp = true) {
    return request({
        url: API_BASE_URL + (isSignUp?'/users/signup':'/users/'),
        method: 'POST',
        body: user
    });
}

export function saveCategoryRequest(category) {
    return request({
        url: API_BASE_URL + '/news/categories/',
        method: 'POST',
        body: category
    });
}

export function saveDisplaySectionRequest(displaySection) {
    return request({
        url: API_BASE_URL + '/news/displaysections/',
        method: 'POST',
        body: displaySection
    });
}

export function saveNewsRequest(news) {
    return request({
        url: API_BASE_URL + '/news/',
        method: 'POST',
        body: news
    });
}

export function saveDataRequest(type, data) {
    return request({
        url: API_BASE_URL + '/' + type + '/',
        method: 'POST',
        body: data
    });
}

export function fetchUserRequest(id) {
    return request( {
        url: API_BASE_URL + '/users/'+ id,
        method: 'GET'
    })
}

export function fetchCategoriesRequest() {
    return request({
        url: API_BASE_URL + '/news/categories/',
        method: 'GET'
    });
}

export function fetchCountRequest() {
    return request({
        url: API_BASE_URL + '/search/count',
        method: 'GET'
    });
}

export function fetchSearchRequest(terms) {
    return request({
        url: API_BASE_URL + '?q=' + terms,
        method: 'GET'
    }, false);
}

export function fetchRequest(type, id) {
    return request({
        url: API_BASE_URL + '/' + type  + '/user/' + id,
        method: 'GET'
    });
}

export function fetchDisplaySectionsRequest() {
    return request({
        url: API_BASE_URL + '/news/displaysections/',
        method: 'GET'
    });
}

export function fetchNewsRequest() {
    return request({
        url: API_BASE_URL + '/news/',
        method: 'GET'
    });
}

export function fetchAuthorsRequest() {
    return request({
        url: API_BASE_URL + '/news/authors/',
        method: 'GET'
    });
}

export function deleteCategoryRequest(id) {
    return request({
        url: API_BASE_URL + '/news/categories/' + id,
        method: 'DELETE'
    });
}


export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export function deleteDataRequest(type, id) {
    return request({
        url: API_BASE_URL + '/' + type + "/" + id,
        method: 'DELETE'
    });
}

export function deleteDisplaySectionRequest(id) {
    return request({
        url: API_BASE_URL + '/news/displaysections/' + id,
        method: 'DELETE'
    });
}
/*
export function getAllPolls(page, size) {
    page = page || 0;
    size = size || POLL_LIST_SIZE;

    return request({
        url: API_BASE_URL + "/polls?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

export function createPoll(pollData) {
    return request({
        url: API_BASE_URL + "/polls",
        method: 'POST',
        body: JSON.stringify(pollData)         
    });
}

export function castVote(voteData) {
    return request({
        url: API_BASE_URL + "/polls/" + voteData.pollId + "/votes",
        method: 'POST',
        body: JSON.stringify(voteData)
    });
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/signin",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function checkUsernameAvailability(username) {
    return request({
        url: API_BASE_URL + "/user/checkUsernameAvailability?username=" + username,
        method: 'GET'
    });
}

export function checkEmailAvailability(email) {
    return request({
        url: API_BASE_URL + "/user/checkEmailAvailability?email=" + email,
        method: 'GET'
    });
}

export function getCurrentUser() {
    if(!Auth.isUserAuthenticated()) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function getUserProfile(username) {
    return request({
        url: API_BASE_URL + "/users/" + username,
        method: 'GET'
    });
}

export function getUserCreatedPolls(username, page, size) {
    page = page || 0;
    size = size || POLL_LIST_SIZE;

    return request({
        url: API_BASE_URL + "/users/" + username + "/polls?page=" + page + "&size=" + size,
        method: 'GET'
    });
}

export function getUserVotedPolls(username, page, size) {
    page = page || 0;
    size = size || POLL_LIST_SIZE;

    return request({
        url: API_BASE_URL + "/users/" + username + "/votes?page=" + page + "&size=" + size,
        method: 'GET'
    });
}
*/