import '../../../node_modules/bootstrap/scss/bootstrap.scss';
import '../../assets/css/font-awesome.min.css';
import '../../assets/css/animate.css';
import '../../assets/css/style.scss';
import '../../assets/css/responsive.scss';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {userSignInFrontend, userSignOut} from "../../redux/actions";
import UserAvatar from "./UserAvatar";
import UserAvatarLogIn from "./UserAvatarLogIn";
import {push} from 'connected-react-router'

class Navigation extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.updateWindow = this.updateWindow.bind(this)
  }

  state = {
    collapsed: true,
    visible: false,
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  updateWindow = () => {
    let elementId = document.getElementById("navbar");
    if (window.scrollY > 70) {
      elementId.classList.add("is-sticky");
      window.history.pushState("", document.title, window.location.pathname);
    } else {
      elementId.classList.remove("is-sticky");
    }
  }

  componentDidMount() {
    document.addEventListener("scroll", this.updateWindow);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.updateWindow);
  }

  render() {
    const {collapsed} = this.state;
    const classOne = collapsed ? 'collapse navbar-collapse' : 'navbar-collapse collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
    const {authUser, push, userSignOut, userSignInFrontend} = this.props;
    return (
      <nav id="navbar" className="navbar navbar-expand-lg navbar-light bg-light header-sticky">
        <div className="container">
          <Link className="navbar-brand" to="/aindex/home">
            <img id="main-logo" src={require('../../assets/images/logo4.png')} alt="partner"/>
          </Link>
          <button
            onClick={this.toggleNavbar}
            className={classTwo}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/*<div className={classOne} id="navbarSupportedContent">*/}
          {/*  <ul className="navbar-nav ml-auto">*/}
          {/*    <li className="nav-item">*/}
          {/*      {authUser ? <UserAvatar push={push} signOut={userSignOut} authUser={authUser}/> : <UserAvatarLogIn signIn={userSignInFrontend} />}*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default withRouter(connect(mapStateToProps, {userSignInFrontend, userSignOut, push})(Navigation));
