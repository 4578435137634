import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import Persistence from "./Persistence";
import ToDo from "./ToDo";


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  persistence: Persistence,
  toDo: ToDo,
  commonData: Common,
});
