import {
  AUTH_DATA,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL, SHOW_MESSAGE,
  SIGNOUT_USER_SUCCESS, USER_DATA,
} from "../constants/ActionTypes";
import {fetchUserRequest, login, logout, rawRequest, saveUserRequest} from "../../util/APIUtils";
import {push} from 'connected-react-router'
import Auth from "../../util/Auth";
import {API_BASE_URL} from "../constants";

export const userSignInFrontend = (email, password) => (dispatch) => {
  dispatch(fetchStart());
  return login({
    'grant_type': 'password',
    'username': email,
    'password': password
  })
    .then((data) => {
      if (data.user) {
        Auth.authenticateUser(data);
        dispatch(fetchSuccess());
        dispatch(showMessage("Login realizado. Bem vindo"))
        dispatch(authData(data));
      } else {
        dispatch(fetchError(data.error));
        dispatch(showMessage("Erro. Tente novamente mais tarde."))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.error_description));
    });
};



export const userSignUpFrontend = ({email, username, password, displayName}) => (dispatch) => {
  dispatch(fetchStart());

  function saveUserFrontend(user, isSignUp = true) {
    const options = {
      url: API_BASE_URL + ('/users/signup'),
      method: 'POST',
      body: user
    };
    const request = {
      method: options.method,
      headers: new Headers()
    };
    request.headers.append('Content-Type', 'application/json');
    request.body = JSON.stringify(options.body);
    return rawRequest(options.url, request);
  }
  return saveUserFrontend({
    'email': email,
    'userName': username,
    'password': password,
    'displayName': displayName
  })
    .then((user) => {
      if (user) {
        dispatch(userSignInFrontend(username, password));
        dispatch(fetchSuccess());
        dispatch(showMessage("Cadastro feito com sucesso."))
      } else {
        dispatch(fetchError("Network Error"));
        dispatch(showMessage("Por favor tente mais tarde."))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.message));
      dispatch(showMessage("Por favor tente mais tarde."))
    });
};

export const userSignUp = ({email, username, password, displayName}) => (dispatch) => {
  dispatch(fetchStart());
  return saveUserRequest({
    'email': email,
    'userName': username,
    'password': password,
    'displayName': displayName
  })
    .then((data) => {
      if (data) {
        dispatch(fetchSuccess());
        dispatch(authData(data));
        dispatch(push('/app/dash'))
      } else {
        dispatch(fetchError("Network Error"));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.message));
    });
};

export const userSignIn = (email, password) => (dispatch) => {
  dispatch(fetchStart());
  return login({
    'grant_type': 'password',
    'username': email,
    'password': password
  })
    .then((data) => {
      // temp to bypass login
      dispatch(authData({ user: { username: 'Felipe', name: 'Felipe', userName: 'Felipe', token: 'sdfasfs',
        profileImage: null
        }} ));

      if (data.user) {
        Auth.authenticateUser(data);
        dispatch(fetchSuccess());
        dispatch(showMessage("Login realizado."))
        dispatch(authData(data));
        dispatch(push('/app/dash'))
      } else {
        console.log('Erro data: ', data);
        dispatch(fetchError(data.error));
        dispatch(showMessage("Por favor tente novamente."))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.error_description));
    });
};

export const fetchUser = (id) => (dispatch) => {
  dispatch(fetchStart());
  return fetchUserRequest(id)
    .then((data) => {
      if (data) {
        dispatch(fetchSuccess());
        dispatch(userData(data));
      } else {
        dispatch(fetchError(data.error));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.error_description));
    });
};

export const saveUser = (user, successCallback) => (dispatch) => {
  dispatch(fetchStart());
  return saveUserRequest(user, false)
    .then((data) => {
      if (data) {
        console.log('Request ->', data);
        dispatch(fetchSuccess());
        dispatch(userData(data));
        successCallback(data)
      } else {
        dispatch(fetchError(data.error));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.error_description));
    });
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    return logout()
      .then((data) => {
        if (data) {
          Auth.deauthenticateUser();
          dispatch(fetchSuccess());
          dispatch(signoutUserSuccess());
        } else {
          dispatch(fetchError(data.error));
        }
      }).catch(function (error) {
        dispatch(fetchError(error.message));
        console.log("Error****:", error.message);
      });
  }
};
export const signoutUserSuccess = () => ({
  type: SIGNOUT_USER_SUCCESS
})
export const userData = (user) => ({
  type: USER_DATA,
  payload: user
});
export const authData = (user) => ({
  type: AUTH_DATA,
  payload: user
});
export const fetchStart = () => ({
  type: FETCH_START
});
export const fetchSuccess = () => ({
  type: FETCH_SUCCESS
});
export const showMessage = (msg) => ({
  type: SHOW_MESSAGE,
  payload: msg
});
export const fetchError = (msg) => ({
  type: FETCH_ERROR,
  payload: msg
});
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
