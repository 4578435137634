import React, {useState} from 'react'
import ProfilePesquisador from "./Profiles/ProfilePesquisador";
import ProfilePrograma from "./Profiles/ProfilePrograma";
import ProfilePatente from "./Profiles/ProfilePatente";
import ProfileMarca from "./Profiles/ProfileMarca";
import ProfileLaboratorio from "./Profiles/ProfileLaboratorio";
import ProfileEquipamento from "./Profiles/ProfileEquipamento";
import ProfileEmpresa from "./Profiles/ProfileEmpresa";
import ProfileDocumento from "./Profiles/ProfileDocumento";
import ProfileCultivar from "./Profiles/ProfileCultivar";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";

const SearchListItem = (props) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  const getTypeInfo = (type, item) => {
    switch (type) {
      case 'cultivar':
        return {
          icon: <i className="fa fa-leaf"></i>,
          label: "Cultivar",
          info:
            <div className="info">
              <h3>Cultivar:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfileCultivar show={showDrawer}
                                    close={onClose}
                                    visible={visible}
                                    item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }
      case 'documentos':
        return {
          icon: <i className="fa fa-briefcase"></i>,
          label: "Documento",
          info:
            <div className="info">
              <h3>Documento:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfileDocumento show={showDrawer}
                                     close={onClose}
                                     visible={visible}
                                     item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }
      case 'empresas':
        return {
          icon: <i className="fa fa-building"></i>,
          label: "Empresa",
          info:
            <div className="info">
              <h3>Empresa:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfileEmpresa show={showDrawer}
                                   close={onClose}
                                   visible={visible}
                                   item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }
      case 'empresas_juniores':
        return {
          icon: <i className="fa fa-building"></i>,
          label: "Empresa",
          info:
            <div className="info">
              <h3>Empresa:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfileEmpresa show={showDrawer}
                                   close={onClose}
                                   visible={visible}
                                   item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }
      case 'equipamentos':
        return {
          icon: <i className="fa fa-cogs"></i>,
          label: "Equipamento",
          info:
            <div className="info">
              <h3>Equipamento:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfileEquipamento show={showDrawer}
                                       close={onClose}
                                       visible={visible}
                                       item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }
      case 'laboratorios':
        return {
          icon: <i className="fa fa-flask"></i>,
          label: "Laboratorio",
          info:
            <div className="info">
              <h3>Laboratório:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfileLaboratorio show={showDrawer}
                                       close={onClose}
                                       visible={visible}
                                       item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }
      case 'lattesuser':
        return {
          icon: <i className="fa fa-users"></i>,
          label: "Pesquisador",
          info:
            <div className="info">
              <h3>Pesquisador:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfilePesquisador show={showDrawer}
                                       close={onClose}
                                       visible={visible}
                                       item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }
      case 'marcas':
        return {
          icon: <i className="fa fa-tag"></i>,
          label: "Marcas",
          info:
            <div className="info">
              <h3>Marca:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfileMarca show={showDrawer}
                                 close={onClose}
                                 visible={visible}
                                 item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }

      case 'patentes':
        return {
          icon: <i className="fa fa-registered"></i>,
          label: "Patente",
          info:
            <div className="info">
              <h3>Patente:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfilePatente show={showDrawer}
                                   close={onClose}
                                   visible={visible}
                                   item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }

      case 'programas':
        return {
          icon: <i className="fa fa-clipboard"></i>,
          label: "Programa",
          info:
            <div className="info">
              <h3>Programa:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfilePrograma show={showDrawer}
                                    close={onClose}
                                    visible={visible}
                                    item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }


      default:
        return {
          icon: <i className="fa fa-users"></i>,
          label: "Pesquisador",
          info:
            <div className="info">
              <h3>Pesquisador:</h3>
              <div className="cateinfo">{nome}</div>
            </div>,
          profile: <ProfilePesquisador show={showDrawer}
                                       close={onClose}
                                       visible={visible}
                                       item={item}/>,
          moreInfo: <div className="btn">
            <button onClick={showDrawer}>Detalhes</button>
          </div>
        }
    }
  };

  const {nome} = props.item;
  const {icon, profile, info, moreInfo} = getTypeInfo(props.type, props.item);
  return (
    <div className="search-list-item list-item-wrapper">
      <Row align="middle" justify="center">
        <Col sm={24} md={3}>
          <div className="icon">
            {icon}
          </div>
        </Col>
        <Col sm={24} md={15}>
          {info}
        </Col>
        <Col sm={24} md={6} style={{textAlign:'center'}}>
          {moreInfo}
        </Col>
      </Row>
      {profile}
    </div>
  );
};

export default SearchListItem;




        