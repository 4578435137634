import React, {useState} from 'react';
import {Button, Row, Drawer, Form, Input, Col} from "antd";

const pStyle = {
  fontSize: 16,
  color: 'rgba(0,0,0,0.85)',
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

const ProfilePesquisador = (props) => {
  const DescricaoItem = ({content}) => (
    <div
      style={{
        fontSize: 14,
        lineHeight: '22px',
        marginBottom: 7,
        color: 'rgba(0,0,0,0.65)',
      }}
    >
      <p
        style={{
          marginRight: 8,
          display: 'inline-block',
          color: 'rgba(0,0,0,0.85)',
        }}
      >
        Descrição:
      </p>
      <span dangerouslySetInnerHTML={{__html: content}}></span>
    </div>
  );

  const DescriptionItem = ({title, content}) => (
    <div
      style={{
        fontSize: 14,
        lineHeight: '22px',
        marginBottom: 7,
        color: 'rgba(0,0,0,0.65)',
      }}
    >
      <p
        style={{
          marginRight: 8,
          display: 'inline-block',
          color: 'rgba(0,0,0,0.85)',
        }}
      >
        {title}:
      </p>
      {content}
    </div>
  );


  // const Card = Card;
  // switch(card) {
  //   case  (props.type === ' cultivar'):
  //     return {}
  //     break;
  //   case (props.type === 'documentos'):
  //     return {}
  //     break;
  //   case (props.type === 'empresas'):
  //     return {}
  //     break;
  //   case (props.type === 'empresas_juniores'):
  //     return {}
  //     break;
  //   case (props.type === 'lattesuser'):
  //     return {}
  //     break;
  //   case (props.type === 'marcas'):
  //     return {}
  //     break;
  //   case (props.type === 'patentes'):
  //     return {}
  //     break;
  //   case (props.type === 'programas'):
  //     return {}
  //     break;
  // }

  const {getFieldDecorator} = props.form;


  return (
    <Drawer
      width={(global.window.innerWidth > 520)? "40%" : "90%"}
      placement="right"
      closable={false}
      onClose={props.close}
      visible={props.visible}
    >
      <p style={pStyle}>Detalhes</p>
      <Col>
        <Row span={12}>
          {(props.type === 'lattesuser')}
          <DescriptionItem title="Nome" content={props.item.nome}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Campus" content={props.item.campus}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Centro" content={props.item.centro}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Departamento" content={props.item.departamento}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Email" content={props.item.email}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Área de atuação" content={props.item.tipo_vinculo}/>
        </Row>
      </Col>
{/* 
      <Col>
        <Row span={12}>
          {(props.type === 'empresas')}
          <DescriptionItem title="Nome" content={props.item.nome}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Sigla" content={props.item.sigla}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Website" content={props.item.site}/>
        </Row>
        <Row span={12}>
          <DescricaoItem title="Descricao" content={props.item.descricao}/>
        </Row>
      </Col>

      <Col>
        <Row span={12}>
          {(props.type === 'laboratorios')}
          <DescriptionItem title="Nome" content={props.item.nome}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Sigla" content={props.item.sigla}/>
        </Row>
        <Row span={12}>
          <DescriptionItem title="Website" content={props.item.site}/>
        </Row>
        <Row span={12}>
          <DescricaoItem title="Descricao" content={props.item.descricao}/>
        </Row>
      </Col> */}

      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button onClick={props.close} style={{marginRight: 8}}>
          Fechar
        </Button>
      </div>
    </Drawer>
  );
};

export default Form.create()(ProfilePesquisador);
