import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  AUTH_DATA, USER_DATA
} from "../constants/ActionTypes";
import Auth from "../../util/Auth";

const INIT_STATE = {
  initURL: '',
  authUser: Auth.getAuthenticatedUser(),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: '/app/dashboard/default'
      }
    }
    case USER_DATA: {
      return {
        ...state,
        authUser: {
          ...state.authUser,
          user: {
            ...action.payload
          }
        }
      }
    }
    case AUTH_DATA: {
      return {
        ...state,
        authUser: {
          ...action.payload
        }
      };
    }
    default:
      return state;
  }
}
