import {ACCESS_AUTH, ACCESS_TOKEN} from "../redux/constants";

class Auth {

  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} auth
   */
  static authenticateUser(auth) {
    localStorage.setItem(ACCESS_AUTH, JSON.stringify(auth));
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    return localStorage.getItem(ACCESS_AUTH) !== null;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    localStorage.removeItem(ACCESS_AUTH);
  }

  /**
   * Get the auth object.
   *
   * @returns {Object}
   */

  static getAuthenticatedUser() {
    return JSON.parse(localStorage.getItem(ACCESS_AUTH));
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    return Auth.getAuthenticatedUser()[ACCESS_TOKEN];
  }

  static requestHeaders() {
    return {'AUTHORIZATION': `Bearer ${this.getToken()}`}
  }

  static requireAuth(nextState, replace) {
    if (Auth.isUserAuthenticated()) {
      replace({
        pathname: '/login',
        state: { nextPathname: nextState.location.pathname }
      })
    }
  }

}

export default Auth;

// const headers = this.requestHeaders();
// const request = new Request('http://localhost:5000/api/v1/cats', {
//   method: 'GET',
//   headers: headers
// });

// return fetch(request).then(response => {
//   return response.json();
// }).catch(error => {
//   return error;
// });

// {/*<Route path="/cats" component={CatsPage}*/}
//        // onEnter={requireAuth}>

