import React from 'react';
// import Select from "react-select";
import {fetchSearch} from "../../redux/actions";
import {Input} from "antd";
import {connect} from "react-redux";
import {history} from "../../redux/store";

//const {Option} = Select;

const options = [
  {type: 'all', label: 'Todos'},
  {type: 'pesquisador', label: 'Pesquisadores'},
  {type: 'instituicao', label: 'Instituições'},
  {type: 'empresa', label: 'Empresas'},
  {type: 'laboratorio', label: 'Laboratórios'},
  {type: 'equipamento', label: 'Equipamentos'},
];

export function getType(type) {
  switch(type) {
    case 'all':
      return {
        type: 'all',
        name: 'Todos'
      }
    case 'pesquisador':
      return {
        type: 'pesquisador',
        name: 'Pesquisadores'
      }
    case 'instituicao':
      return {
        type: 'instituicao',
        name: 'Intituições'
      }
    case 'empresa':
      return {
        type: 'empresa',
        name: 'Empresas'
      }
    case 'equipamento':
      return {
        type: 'equipamento',
        name: 'Equipamentos'
      }
    case 'laboratorio':
      return {
        type: 'laboratorio',
        name: 'Laboratórios'
      }
    default:
      return {
        type: 'pesquisador',
        name: 'Pesquisadores'
      }
  }
}

class Search extends React.Component {
  state = {
    type: options[0].type,
    typeLabel: options[0].label,
    term: ''
  }

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  handleTerm = event => {
    this.setState({term: event.target.value});
  };

  handleSearch = () => {
    const location = this.props.location;
    const {term} = this.state;
    const queryTerm = encodeURIComponent(term);
    // console.log(queryTerm)
    this.props.fetchSearch(queryTerm)
    if (location.pathname !== '/aindex/search') {
      history.push('/aindex/search')
    }
  }

  handleSelectChange = type => {
    console.log('value type', type);
    this.setState({
      type: type,
      typeLabel: getType(type),
    });
  };

  render() {
    return (
      <section id="search" className="search-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card2 acik-renk-form2">
                <div className="card-body2">
                  <div style={{
                    marginBottom: "10px"
                  }} className="row">
                    {/*<div className="col-md-3 col-sm-6">*/}
                    {/*  <div className="form-group ">*/}
                    {/*    <Select*/}
                    {/*      size="large"*/}
                    {/*      defaultValue="Categorias"*/}
                    {/*      style={{width: 100 + '%'}}*/}
                    {/*      onChange={this.handleSelectChange}>*/}
                    {/*      {options.map( (item, index) => (*/}
                    {/*        <Option key={'option-'+index+item.type} value={item.type}>{item.label}</Option>*/}
                    {/*      ))}*/}
                    {/*    </Select>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="col-md-12 col-sm-12">
                      <Input
                        size="large"
                        type="text"
                        name="term"
                        onChange={this.handleTerm}
                        onKeyDown={this.handleKeyDown}
                        placeholder="Inovação começa aqui..."/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button type="button" onClick={this.handleSearch} className="btn btn-danger wrn-btn">Pesquisar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({persistence, commonData, router}) => {
  const {location} = router;
  const {loading} = commonData;
  const {search} = persistence;
  return {loading, search, location}
};

export default connect(mapStateToProps, {fetchSearch})(Search);
