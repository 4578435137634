import React from 'react';
import {Link} from 'react-router-dom';

class HowWeWork2 extends React.Component {
  render() {
    return (
      <section className="ctr-area">
        {/*<section id="areas" className="who-we-are ptb-80 ">*/}
        {/*<section id="processo" className="about-area ptb-80 bg-f6f6f6">*/}
        <div className="container">
          <div className="section-title">
            <h2>Conheça mais sobre a nossas <span>parcerias</span></h2>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6"><Link to="./empresas">
              <div className="single-how-work2">
                <h3>Empresas</h3>
                <h4>Nossas Empresas Filhas UFSCar</h4>
              </div>
            </Link>
            </div>

            <div className="col-lg-4 col-md-6"><a href="http://ain.ufscar.br/saginweb/">
              <div className="single-how-work2">
                <h3>SAGIN</h3>
                <h4>Sistema Aberto de Gestão da Inovação</h4>
              </div>
            </a>
            </div>

            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0"><a href="http://ain.ufscar.br/mapa/">
              <div className="single-how-work2">
                <h3>Mapa</h3>
                <h4>Explore o Mapa da Inovação</h4>
              </div>
            </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HowWeWork2;