import React from 'react'
import SearchListItem from "./SearchListItem";
import './SearchList.scss'
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";

const getChunks = (items, perChunk = 40) => {
  return items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

class SearchList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      total: 0,
      chunk: 0,
      rlist: [],
      count: 0,
      loadButton: true
    }
  }

  componentDidMount() {
    this.resultList(0)
    this.setState({
      count: this.getCount()
    })
  }

  loadMore = () => {
    const num = this.state.chunk + 1;
    this.setState({chunk: num})
    this.resultList(num)
  }
  getCount = () => {
    // const {pesquisador, empresa, instituicao, equipamento, laboratorio} = this.props.data;
    // return pesquisador.length + empresa.length + instituicao.length + equipamento.length + laboratorio.length
  }

  resultList = (chunk) => {

    console.log("--------------------------------------")
    console.log(this.props.data)

    // const data = this.props.data;

    if (this.props.data[0]) {

      const cultivar = this.props.data[0].cultivar;
      const documentos = this.props.data[0].documentos;
      const empresas = this.props.data[0].empresas;
      const empresas_juniores = this.props.data[0].empresas_juniores;
      const equipamentos = this.props.data[0].equipamentos;
      const laboratorios = this.props.data[0].laboratorios;
      const lattesuser = this.props.data[0].lattesuser;
      const marcas = this.props.data[0].marcas;
      const patentes = this.props.data[0].patentes;
      const programas = this.props.data[0].programas;


      const total = cultivar.length + documentos.length + empresas.length
        + empresas_juniores.length + equipamentos.length + laboratorios.length
        + lattesuser.length + marcas.length + patentes.length + programas.length


      const cchunk = getChunks(cultivar);
      const dchunk = getChunks(documentos);
      const echunk = getChunks(empresas);
      const ejchunk = getChunks(empresas_juniores);
      const eqchunk = getChunks(equipamentos);
      const labchunk = getChunks(laboratorios);
      const latchunk = getChunks(lattesuser);
      const mchunk = getChunks(marcas);
      const patchunk = getChunks(patentes);
      const prochunk = getChunks(programas);


      const newList = [];

      if (cchunk[chunk]) newList.push(...cchunk[chunk].map(item =>
        <Col xs={24} sm={24} md={12}>
          <SearchListItem key={item.id + item.name} type="cultivar" item={item}/>
        </Col>
      ));
      if (dchunk[chunk]) newList.push(...dchunk[chunk].map(item =>
        <Col xs={24} sm={24} md={12}>
          <SearchListItem key={item.id + item.name} type="documentos" item={item}/>
        </Col>
      ));
      if (echunk[chunk]) newList.push(...echunk[chunk].map(item =>
        <Col xs={24} sm={24} md={12}>
          <SearchListItem key={item.id + item.name} type="empresas" item={item}/>
        </Col>
      ));
      if (ejchunk[chunk]) newList.push(...ejchunk[chunk].map(item =>
        <Col xs={24} sm={24} md={12}>
          <SearchListItem key={item.id + item.name} type="empresas_juniores" item={item}/>
        </Col>
      ));
      if (eqchunk[chunk]) newList.push(...eqchunk[chunk].map(item =>
        <Col xs={24} sm={24} md={12}>
          <SearchListItem key={item.id + item.name} type="equipamentos" item={item}/>
        </Col>
      ));
      if (labchunk[chunk]) newList.push(...labchunk[chunk].map(item =>
        <Col xs={24} sm={24} md={12}>
          <SearchListItem key={item.id + item.name} type="laboratorios" item={item}/>
        </Col>
      ));
      if (latchunk[chunk]) newList.push(...latchunk[chunk].map(item =>
        <Col xs={24} sm={24} md={12}>
          <SearchListItem key={item.id + item.name} type="lattesuser" item={item}/>
        </Col>
      ));
      if (mchunk[chunk]) newList.push(...mchunk[chunk].map(item =>
        <Col xs={24} sm={24} md={12}>
          <SearchListItem key={item.id + item.name} type="marcas" item={item}/>
        </Col>
      ));
      if (patchunk[chunk]) newList.push(...patchunk[chunk].map(item =>
        <Col xs={24} sm={24} md={12}>
          <SearchListItem key={item.id + item.name} type="patentes" item={item}/>
        </Col>
      ));
      if (prochunk[chunk]) newList.push(...prochunk[chunk].map(item =>
        <Col xs={24} sm={24} md={12}>
          <SearchListItem key={item.id + item.name} type="programas" item={item}/>
        </Col>
      ));

      if (newList.length) {
        this.setState({loadButton: false, total: total})
      }
      this.setState({
        rlist: this.state.rlist.concat(newList),
        total: total
      })
    }
  };

  render() {
    const {count, rlist, total} = this.state

    return (
      <div className="container search-list">
        {(count !== 0) ?
          <h1 style={{width: '100%', textAlign: 'left', margin: '40px'}}>{this.getCount()} Resultados - {total} items
            encontrados</h1>
          : <h1 style={{width: '100%', textAlign: 'left', margin: '40px'}}>Sem resultados</h1>
        }
        <div className="searchList">
          <Row gutter={[16, 16]} align="middle">
            {/*<Col xs={2} sm={4} md={12} lg={8} xl={10}> Col </Col>*/}
            {rlist}
          </Row>
        </div>

        {(count !== 0) ?
          <button className="load-more-button" onClick={this.loadMore}>Load More...</button> : null
        }
      </div>
    );
  }
};

export default SearchList;
