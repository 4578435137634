import {
  CATEGORIES_FETCH_SUCCESS,
  DISPLAYSECTIONS_FETCH_SUCCESS,
  NEWS_FETCH_SUCCESS,
  AUTHORS_FETCH_SUCCESS,
  CATEGORY_SAVE_SUCCESS,
  DISPLAYSECTION_SAVE_SUCCESS,
  NEWS_SAVE_SUCCESS,
  CATEGORY_DELETE_SUCCESS,
  DISPLAYSECTION_DELETE_SUCCESS, DATA_FETCH_SUCCESS, SET_EDIT_ITEM
} from "../constants/ActionTypes";
import {
  deleteCategoryRequest, deleteDataRequest, deleteDisplaySectionRequest,
  fetchAuthorsRequest,
  fetchCategoriesRequest, fetchDisplaySectionsRequest, fetchNewsRequest, fetchRequest, fetchSearchRequest,
  saveCategoryRequest, saveDataRequest,
  saveDisplaySectionRequest, saveNewsRequest,
} from "../../util/APIUtils";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS, SEARCH_SUCCESS,
} from "../constants/ActionTypes";

export const fetchSearch = (terms) => (dispatch) => {
  dispatch({type: FETCH_START});
  return fetchSearchRequest(terms)
    .then((data) => {
      if (data) {
        dispatch(searchSuccess({ data: data}));
        dispatch({type: FETCH_SUCCESS});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error});
    });
  // let mockData = [];
  // for (let i = 0; i < 6; i++) {
  //   mockData.push(
  //     {
  //       id: i,
  //       name: `Pesquidador ${i}`,
  //       category: `categoria ${i}`,
  //       summary: 'lorem ipsum dolores pasqualis deractum vacilis musunis tranquilis',
  //       img: 'http://lorempixel.com/70/70/sports/',
  //       address: 'Rua Joao Abdenur Filho, 1343 - Sao Carlos SP'
  //     }
  //   )
  // }
};

export const saveCategory = ({payload}) => {
  return (dispatch) => {
    const {category, successCallback} = payload;
    dispatch({type: FETCH_START});
    return saveCategoryRequest(category)
      .then((data) => {
        if (data) {
          console.log('Request ->', data);
          dispatch({type: FETCH_SUCCESS});
          dispatch(categorySaveSuccess(data));
          successCallback(data)
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const saveDisplaySection = ({payload}) => {
  return (dispatch) => {
    const {displaySection, successCallback} = payload;
    dispatch({type: FETCH_START});
    return saveDisplaySectionRequest(displaySection)
      .then((data) => {
        if (data) {
          console.log('Request ->', data);
          dispatch({type: FETCH_SUCCESS});
          dispatch(displaySectionSaveSuccess(data));
          successCallback(data)
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const saveNews = ({payload}) => {
  return (dispatch) => {
    const {news, successCallback} = payload;
    dispatch({type: FETCH_START});
    return saveNewsRequest(news)
      .then((data) => {
        if (data) {
          console.log('Request ->', data);
          dispatch({type: FETCH_SUCCESS});
          dispatch(newsSaveSuccess(data));
          successCallback(data)
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const saveData = (type, payload) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return saveDataRequest(type, payload)
      .then((data) => {
        if (data) {
          // console.log('Save data Request ->', data);
          dispatch({type: FETCH_SUCCESS});
          dispatch(fetchData(type, payload.userId))
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const fetchData = (type, id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return fetchRequest(type, id)
      .then((data) => {
        if (data) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(dataFetchSuccess(type, data));
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const deleteData = (type, payload) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return deleteDataRequest(type, payload.id)
      .then((data) => {
        if (data) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(fetchData('instituicao', payload.userId))
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const fetchCategories = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return fetchCategoriesRequest()
      .then((data) => {
        if (data) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(categoriesFetchSuccess(data));
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const fetchDisplaySections = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return fetchDisplaySectionsRequest()
      .then((data) => {
        if (data) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(displaySectionsFetchSuccess(data));
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const fetchNews = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return fetchNewsRequest()
      .then((data) => {
        if (data) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(newsFetchSuccess(data));
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const fetchAuthors = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return fetchAuthorsRequest()
      .then((data) => {
        if (data) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(authorsFetchSuccess(data));
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const deleteCategory = ({payload}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return deleteCategoryRequest(payload)
      .then((data) => {
        if (data) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(categoryDeleteSuccess(data));
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const deleteDisplaySection = ({payload}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    return deleteDisplaySectionRequest(payload)
      .then((data) => {
        if (data) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(displaySectionDeleteSuccess(data));
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  }
};

export const dataFetchSuccess = (type, data) => {
  return {
    type: DATA_FETCH_SUCCESS,
    payload: {type, data}
  };
};

export const categoriesFetchSuccess = (categories) => {
  return {
    type: CATEGORIES_FETCH_SUCCESS,
    payload: categories
  };
};

export const displaySectionsFetchSuccess = (displaySections) => {
  return {
    type: DISPLAYSECTIONS_FETCH_SUCCESS,
    payload: displaySections
  };
};

export const newsFetchSuccess = (news) => {
  return {
    type: NEWS_FETCH_SUCCESS,
    payload: news
  };
};


export const categorySaveSuccess = (category) => {
  return {
    type: CATEGORY_SAVE_SUCCESS,
    payload: category
  };
};

export const displaySectionSaveSuccess = (displaySection) => {
  return {
    type: DISPLAYSECTION_SAVE_SUCCESS,
    payload: displaySection
  };
};

export const newsSaveSuccess = (news) => {
  return {
    type: NEWS_SAVE_SUCCESS,
    payload: news
  };
};

export const categoryDeleteSuccess = (id) => {
  return {
    type: CATEGORY_DELETE_SUCCESS,
    payload: id
  };
};

export const displaySectionDeleteSuccess = (id) => {
  return {
    type: DISPLAYSECTION_DELETE_SUCCESS,
    payload: id
  };
};

export const authorsFetchSuccess = (authors) => {
  return {
    type: AUTHORS_FETCH_SUCCESS,
    payload: authors
  };
};

export const searchSuccess = (list) => {
  return {
    type: SEARCH_SUCCESS,
    payload: list
  }
}

export const setEditItem = (item) => {
  return {
    type: SET_EDIT_ITEM,
    payload: item
  }
}

